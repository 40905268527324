<template>
  <div>
    <section v-if="!loading">
      <h2
        class="container-fluid text-center mb-3 d-flex align-items-center justify-content-between"
      >
        <!-- <hr class="w-100" /> -->
        <div class="w-100">Les talents recherchés :</div>
        <!-- <hr class="w-100" /> -->
      </h2>
      <div
        class="card bg-dark"
        :class="{
          'border border-primary border-2': offre.id_offre == idSelectedOffre,
        }"
        v-for="(offre, i) in pageOfItems"
        :key="i"
        @click="$emit('select-offre', offre.id_offre)"
        style="cursor: pointer"
      >
        <div class="row g-0">
          <!-- <div class="col-4 img-fill d-none position-relative">
          <img
            src="../../assets/img/bg-img-6.jpg"
            class="img-fluid rounded-start position-absolute"
            alt="..."
          />
        </div> -->
          <div class="col-12">
            <div
              class="card-body d-flex flex-column justify-content-between h-100"
            >
              <div class="d-flex flex-column">
                <h5 class="card-title text-primary">{{ offre.title_offre }}</h5>
                <!-- <p
                class="card-text mb-2 description"
                v-html="offre.description_offre"
              ></p> -->
                <p class="card-text mb-2">
                  {{ offre.name_type_offre }} -
                  {{ offre.name_categorie_offre }}
                </p>
                <p class="card-text mb-2">{{ offre.profil_offre }}</p>
                <p class="card-text mb-2">{{ offre.lieu_offre }}</p>
              </div>
              <div class="d-flex justify-content-between">
                <p class="card-text m-0">
                  <small class="text-muted"
                    >Publié
                    {{
                      moment(offre.date_offre).calendar(null, {
                        sameWeek: "ddd",
                        sameElse: "DD MMMM YYYY",
                        sameDay: "[Aujourd'hui]",
                        nextDay: "[Demain]",
                        lastWeek: "dddd [dernier]",
                        nextWeek: "dddd",
                        lastDay: "[Hier]",
                      })
                    }}</small
                  >
                </p>
                <p class="card-text m-0 d-none">
                  <small class="text-muted">Par {{ offre.fname_collab }}</small>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <CustomPagination
          :pageSize="4"
          :items.sync="initialData"
          @changePage="onChangePage"
        ></CustomPagination>
      </div>
    </section>
    <div v-if="loading">
      <h2
        class="container-fluid text-center mb-3 d-flex align-items-center justify-content-between"
      >
        <!-- <hr class="w-100" /> -->
        <div class="w-100">Les talents recherchés :</div>
        <!-- <hr class="w-100" /> -->
      </h2>
      <vue-content-loading v-for="index in 6" :key="index" :height="80">
        <rect x="10" y="17" rx="3" ry="3" width="170" height="6" />
        <rect x="10" y="32" rx="3" ry="3" width="250" height="6" />
        <rect x="10" y="47" rx="3" ry="3" width="100" height="6" />
        <rect x="10" y="62" rx="3" ry="3" width="50" height="6" />
        <!-- <rect x="340" y="62" rx="3" ry="3" width="35" height="6" /> -->
      </vue-content-loading>
    </div>
    <div v-if="!pageOfItems.length">
      Aucun résultat ne correspond à votre recherche
    </div>
  </div>
</template>

<script>
import { getAllOffre } from "../../api/offre";
import CustomPagination from "../public/Pagination.vue";
import VueContentLoading from "vue-content-loading";

export default {
  name: "ListeOffre",
  components: { CustomPagination, VueContentLoading },
  props: {
    search: {
      type: Object,
      required: true,
    },
    idSelectedOffre: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      pageOfItems: [],
      initialData: [],
    };
  },
  watch: {
    search: {
      handler(val) {
        getAllOffre(val).then((results) => {
          this.initialData = results.data;
          this.loading = false;
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.loadOffre();
  },
  methods: {
    onChangePage(pageOfItems) {
      window.scrollTo(0, 0);
      this.pageOfItems = pageOfItems;
    },
    loadOffre() {
      getAllOffre(this.search).then((results) => {
        this.$store.dispatch("offreStore/setAllOffre", results.data);
        this.initialData = results.data;
        this.$emit("select-offre", results.data[0].id_offre);
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
section > div {
  margin-bottom: 2rem;
}

section > div:last-child {
  margin-bottom: 0;
}

.img-fill img {
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
}
.description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
