<template>
  <LayoutVisiteur>
    <!-- <section class="bg-primary"> -->
    <section class="bg-offre">
      <div class="container-fluid">
        <div class="row g-4 p-2 py-4">
          <!-- filtre -->
          <div class="px-3 d-flex">
            <div class="col-3 rounded p-3 text-light me-3 d-none">
              <button
                type="button"
                class="btn btn-lg btn-outline-secondary rounded-pill px-4 d-flex align-items-center"
                data-bs-toggle="modal"
                data-bs-target="#modalRejoindre"
              >
                <IcoBrief />
                <div class="ps-2">Postulez !</div>
              </button>

              <!-- modal -->
              <ModalRejoindre />
              <!-- modal -->
            </div>
            <div class="flex-fill">
              <div class="container-fluid px-2">
                <div
                  class="row justify-content-evenly detailOffre p-3 border-0 bg-secondary text-light rounded"
                >
                  <div class="col-4">
                    <label for="inputName" class="form-label">Recherche</label>
                    <input
                      type="text"
                      class="form-control"
                      required
                      placeholder="ex: Développeur"
                      v-model="search.searchkey"
                    />
                  </div>

                  <div class="col-4">
                    <label for="inputName" class="form-label">Type</label>
                    <select
                      class="form-control"
                      required
                      v-model="search.id_type_offre"
                    >
                      <option
                        value="categorie-0"
                        style="display: none"
                      ></option>
                      <option value="">Toutes</option>
                      <option
                        :value="type.id_type_offre"
                        v-for="type in typeOffre"
                        :key="type.id_type_offre"
                      >
                        {{ type.name_type_offre }}
                      </option>
                    </select>
                  </div>

                  <div class="col-4">
                    <label for="inputName" class="form-label">Catégorie</label>
                    <select
                      class="form-control"
                      required
                      v-model="search.id_categorie_offre"
                    >
                      <option
                        value="categorie-0"
                        style="display: none"
                      ></option>
                      <option value="">Toutes</option>
                      <option
                        :value="categorie.id_categorie_offre"
                        v-for="categorie in categorieOffre"
                        :key="categorie.id_categorie_offre"
                      >
                        {{ categorie.name_categorie_offre }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="p-3 border-0 bg-secondary text-light rounded">
              <ListeOffre
                :idSelectedOffre.sync="idSelectedOffre"
                @select-offre="setSelectedId"
                :search.sync="search"
              />
            </div>
          </div>
          <div class="col-lg-6 col-12" ref="DescriptionOffre">
            <div class="row g-4">
              <div class="col-12">
                <div
                  class="detailOffre p-3 border-0 bg-light text-dark rounded"
                >
                  <h2
                    class="container-fluid text-center mb-3 d-flex align-items-center justify-content-between"
                  >
                    <!-- <hr class="w-100" /> -->
                    <div class="w-100">Ceci vous décris peut-être</div>
                  </h2>
                  <div>
                    <DetailOffre :idSelectedOffre.sync="idSelectedOffre" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </LayoutVisiteur>
</template>

<script>
import LayoutVisiteur from "../../layout/LayoutVisiteur.vue";
import ListeOffre from "../../components/visiteur/ListeOffre.vue";
import DetailOffre from "../../components/visiteur/DetailOffre.vue";
import { getAllTypeOffre } from "../../api/typeOffre";
import { getAllCategoryOffre } from "../../api/categorieOffre";
import IcoBrief from "vue-material-design-icons/BriefcaseSearch.vue";
import ModalRejoindre from "../../components/visiteur/ModalRejoindre.vue";

export default {
  name: "OffreEmploiVisiteur",
  components: {
    LayoutVisiteur,
    ListeOffre,
    DetailOffre,
    IcoBrief,
    ModalRejoindre,
  },
  mounted() {
    getAllTypeOffre().then((result) => {
      this.typeOffre = result.data;
    });
    getAllCategoryOffre().then((result) => {
      this.categorieOffre = result.data;
    });
  },
  data() {
    return {
      search: {
        searchkey: "",
        id_type_offre: "",
        id_categorie_offre: "",
      },
      idSelectedOffre: 0,
      loading: false,
      typeOffre: [],
      categorieOffre: [],
      submitted: false,
    };
  },
  methods: {
    setSelectedId(id) {
      this.idSelectedOffre = id;
      this.$refs.DescriptionOffre.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables.scss";

*::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 0.75rem;
  height: 0.25rem;
}
.detailOffre::-webkit-scrollbar-track {
  --bg-opacity: 1;
  background-color: $dark;
  background-color: rgba(251, 255, 219, var(--bg-opacity));
}
.detailOffre::-webkit-scrollbar-thumb {
  --bg-opacity: 1;
  background-color: rgba(251, 255, 219, var(--bg-opacity));
  background-color: $dark;
}
.detailOffre::-webkit-scrollbar-thumb:hover {
  background-color: $primary;
}

.detailOffre {
  overflow-y: auto;
}

.bg-offre {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("../../assets/img/bg-img-11.webp");
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
