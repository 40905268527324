import axios from "axios";
import { BASE_URL } from "../configs";
import FormData from "form-data";
import JSZip from "jszip";

const URL = BASE_URL + "/candidatMk";

export const addCandidatMk = (form, files) => {
  let data = new FormData();
  data.append("candidatMk", JSON.stringify(form));

  var zip = new JSZip();
  files.forEach((e, i) => {
    const data = e.file.replace(/^data:application\/\w+;base64,/, "");
    zip.file(`${e.name}-${i}-file.pdf`, data, { base64: true });
  });

  return zip.generateAsync({ type: "Blob" }).then(function (content) {
    data.append("file", content, "file_");
    return axios.post(`${URL}`, data);
  });
};

export const getAllCandidatMk = () => {
  return axios.get(`${URL}`);
};

export const editCandidatMkById = (id, status) => {
  const data = {
    status_candidat_mk_co: status,
  };
  return axios.put(`${URL}/${id}`, data);
};

export const deleteCandidatMk = (id) => {
  return axios.delete(`${URL}/${id}`);
};
