<template>
  <section v-if="selectedOffre">
    <div class="w-100">
      <div class="mb-3">
        <h4 class="text-primary">{{ selectedOffre.title_offre }}</h4>
        <div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item bg-light text-dark">
              Type: {{ selectedOffre.name_type_offre }}
            </li>
            <li class="list-group-item bg-light text-dark">
              Profil: {{ selectedOffre.profil_offre }}
            </li>
            <li class="list-group-item bg-light text-dark">
              Catégorie : {{ selectedOffre.name_categorie_offre }}
            </li>
            <li class="list-group-item bg-light text-dark">
              Lieu : {{ selectedOffre.lieu_offre }}
            </li>
            <li class="list-group-item bg-light text-dark">
              Date limite :
              {{ moment(selectedOffre.deadline_offre).format("LL") }}
            </li>
          </ul>
        </div>
        <div class="col-12 mb-3 mt-2">
          <button v-if="loading" class="btn btn-primary" type="button" disabled>
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Envoi...
          </button>
          <button
            v-else
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#modalPostule"
            formnovalidate="formnovalidate"
          >
            Postuler
          </button>
        </div>
        <div>
          <h5 class="text-primary">Description du poste</h5>
          <div v-html="selectedOffre.description_offre"></div>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="modalPostule"
      tabindex="-1"
      aria-labelledby="modalLabelmodalPostule"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg modal-dialog-centered">
        <form
          @submit.prevent="confirmPostule"
          class="row gx-5 gy-2 modal-content border-0 bg-light text-dark"
          formnovalidate="formnovalidate"
        >
          <div class="col-12">
            <div class="p-3 border-0 bg-secondary text-light rounded">
              <h2
                class="container-fluid text-center mb-3 d-flex align-items-center justify-content-between"
              >
                <!-- <hr class="w-100" /> -->
                <div class="w-100">Montrez nous vos talents</div>
              </h2>
            </div>
          </div>
          <div class="col-12">
            <label for="inputPoste" class="form-label">Poste</label>
            <input
              type="text"
              v-model="selectedOffre.title_offre"
              class="form-control"
              disabled
            />
          </div>
          <div class="col-md-12">
            <label for="inputName" class="form-label">Nom complet</label>
            <input
              type="text"
              class="form-control"
              v-model="form.full_name_candidat_offre"
              :class="{
                'is-invalid':
                  submitted && $v.form.full_name_candidat_offre.$error,
              }"
            />
            <div
              v-if="submitted && !$v.form.full_name_candidat_offre.required"
              class="invalid-feedback"
            >
              Veuillez insérer votre nom complet
            </div>
          </div>
          <div class="col-md-12">
            <label for="inputTel" class="form-label">Téléphone</label>
            <input
              type="tel"
              class="form-control"
              v-model="form.tel_candidat_offre"
              :class="{
                'is-invalid': submitted && $v.form.tel_candidat_offre.$error,
              }"
            />
            <div
              v-if="submitted && !$v.form.tel_candidat_offre.required"
              class="invalid-feedback"
            >
              Veuillez insérer votre numéro de Téléphone
            </div>
          </div>
          <div class="col-12">
            <label for="inputMail1" class="form-label">Mail</label>
            <input
              type="email"
              class="form-control"
              v-model="form.email_candidat_offre"
              :class="{
                'is-invalid': submitted && $v.form.email_candidat_offre.$error,
              }"
            />
            <div
              v-if="submitted && $v.form.email_candidat_offre.$error"
              class="invalid-feedback"
            >
              <span v-if="!$v.form.email_candidat_offre.required"
                >Veuillez insérer votre adresse mail</span
              >
              <span v-if="!$v.form.email_candidat_offre.email"
                >Veuillez insérer une adresse mail valide</span
              >
            </div>
          </div>
          <div
            v-for="(input, index) in files"
            :key="`${index}`"
            class="col-12"
            :class="index != 0 && 'gy-2'"
          >
            <div class="row">
              <div class="col-4">
                <label for="inputNomAD" class="form-label"
                  >Document requis</label
                >
                <input
                  type="text"
                  class="form-control"
                  required
                  placeholder="ex: curriculum vitae"
                  v-model="files[index].name"
                />
              </div>
              <div class="col-6">
                <label for="inputAD" class="form-label"
                  >Téléverser le fichier</label
                >
                <input
                  type="file"
                  class="form-control"
                  required
                  accept="application/pdf"
                  @change="(e) => changeFile(e, index)"
                />
                <div id="inputAD" class="form-text">En format .pdf</div>
              </div>
              <div
                class="col-2 d-flex flex-column flex-lg-row flex-md-row align-items-center justify-content-center"
              >
                <div
                  @click="addField()"
                  class="btn btn-primary shadow rounded-circle p-1 m-1"
                >
                  <IcoAdd />
                </div>
                <div
                  @click="removeField(index)"
                  v-show="files.length > 1"
                  class="btn btn-outline-primary shadow rounded-circle p-1 m-1"
                >
                  <IcoDel />
                </div>
              </div>
            </div>
          </div>

          <div class="col-12">
            <label for="inputMessage" class="form-label">Remarques</label>
            <textarea
              class="form-control"
              rows="4"
              required
              v-model="form.message_candidat_offre"
              style="resize: none"
            ></textarea>
          </div>
          <div class="col-12">
            <input
              type="checkbox"
              class="form-check-input me-2"
              required
              v-model="consent"
            />
            <span>J'ai bien lu et approuvé les Mentions Légales du site</span>
          </div>
          <div class="col-12">
            <input
              type="checkbox"
              class="form-check-input me-2"
              required
              v-model="cgv"
            />
            <span
              >J'accepte sans réserve les Conditions Générales de MK-co</span
            >
          </div>
          <div class="col-12 mb-4 mt-4 text-end">
            <button
              v-if="loading"
              class="btn btn-primary"
              type="button"
              disabled
            >
              <span
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Envoi...
            </button>
            <button
              v-else
              type="submit"
              class="btn btn-primary"
              formnovalidate="formnovalidate"
            >
              Postuler
            </button>
            <button
              type="button"
              class="btn btn-secondary ms-3"
              data-bs-dismiss="modal"
              ref="close"
            >
              Annuler
            </button>
          </div>
        </form>
      </div>
    </div>
  </section>
  <section v-else>Aucune offre d'emploi sélectionnée</section>
</template>

<script>
import IcoAdd from "vue-material-design-icons/Plus.vue";
import IcoDel from "vue-material-design-icons/Close.vue";
import { required, email } from "vuelidate/lib/validators";
import { addCandidatOffre } from "../../api/candidatOffre";
import { success, error } from "../../utils/toast";
import { socket } from "../../api/socket";

export default {
  name: "DetailOffre",
  props: ["idSelectedOffre"],
  components: { IcoAdd, IcoDel },
  computed: {
    selectedOffre() {
      return this.$store.getters["offreStore/getOffreById"](
        this.idSelectedOffre
      );
    },
  },
  data() {
    return {
      affiche: false,
      consent: false,
      cgv: false,
      form: {
        full_name_candidat_offre: "",
        email_candidat_offre: "",
        tel_candidat_offre: "",
        message_candidat_offre: "",
        /* id_offre: null, */
      },
      files: [{ name: "", file: null }],
      loading: false,
      submitted: false,
      validations: {
        form: {
          full_name_candidat_offre: { required },
          tel_candidat_offre: { required },
          email_candidat_offre: { required, email },
        },
      },
    };
  },
  validations: {
    form: {
      full_name_candidat_offre: { required },
      tel_candidat_offre: { required },
      email_candidat_offre: { required, email },
    },
  },
  methods: {
    addField() {
      this.files.push({ name: "", file: null });
    },
    removeField(index) {
      this.files.splice(index, 1);
    },
    toggleModal() {
      this.affiche = !this.affiche;
      if (this.affiche) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "visible";
      }
    },
    changeFile(e, index) {
      const reader = new FileReader();
      try {
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (event) => {
          const base64 = event.target.result;
          if (base64) {
            this.files[index].file = base64;
          }
        };
      } catch {
        this.files[index].file = null;
      }
      //this.files[index].file = e.target.files[0];
    },
    confirmPostule() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      const isEmptyFile = this.files.some(
        (f) => f.file === null || f.file === ""
      );
      if (isEmptyFile) {
        error("Téléverser le fichier restant");
      } else {
        if (this.consent && this.cgv) {
          this.loading = true;
          addCandidatOffre(
            this.form,
            this.selectedOffre.id_offre,
            this.files
          ).then((result) => {
            //console.log(result);

            if (result.data.error) {
              this.loading = false;
              error(result.data.error, "");
            } else {
              socket.emit("send-notif", {
                data: result.data,
                type: "OFFRE",
                name: this.form.full_name_candidat_offre,
              });
              this.submitted = false;
              this.loading = false;
              this.form.full_name_candidat_offre = "";
              this.form.email_candidat_offre = "";
              this.form.tel_candidat_offre = "";
              this.form.message_candidat_offre = "";
              this.consent = false;
              this.cgv = false;
              this.$refs.close.click();
              success("Candidature envoyé");
            }
          });
        } else {
          error("Veuillez accepter les consentements");
        }
      }
    },
  },
};
</script>

<style scoped>
li {
  font-size: 14px;
}
</style>
