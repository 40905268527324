<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="modalRejoindre"
      tabindex="-1"
      aria-labelledby="modalLabelRejoindre"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg">
        <form
          @submit.prevent="confirm"
          class="modal-content border-0 bg-light text-dark"
          formnovalidate="formnovalidate"
        >
          <div class="modal-header mx-2">
            <h4 class="modal-title text-primary" id="modalLabelRejoindre">
              Rejoindre notre équipe
            </h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="closeModalRejoindre"
            ></button>
          </div>
          <div class="modal-body">
            <section class="row p-2">
              <div class="col-md-6 mb-3">
                <label for="inputName" class="form-label">Nom complet*</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="form.full_name_candidat_mk_co"
                  :class="{
                    'is-invalid':
                      submitted && $v.form.full_name_candidat_mk_co.$error,
                  }"
                />
                <div
                  v-if="submitted && !$v.form.full_name_candidat_mk_co.required"
                  class="invalid-feedback"
                >
                  Veuillez insérer votre nom complet
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <label for="inputTel" class="form-label">Téléphone*</label>
                <input
                  type="tel"
                  class="form-control"
                  v-model="form.tel_candidat_mk_co"
                  :class="{
                    'is-invalid':
                      submitted && $v.form.tel_candidat_mk_co.$error,
                  }"
                />
                <div
                  v-if="submitted && !$v.form.tel_candidat_mk_co.required"
                  class="invalid-feedback"
                >
                  Veuillez insérer votre numéro de téléphone
                </div>
              </div>
              <div class="col-12 mb-3">
                <label for="inputMail1" class="form-label">Mail*</label>
                <input
                  type="email"
                  class="form-control"
                  v-model="form.email_candidat_mk_co"
                  :class="{
                    'is-invalid':
                      submitted && $v.form.email_candidat_mk_co.$error,
                  }"
                />
                <div
                  v-if="submitted && $v.form.email_candidat_mk_co.$error"
                  class="invalid-feedback"
                >
                  <span v-if="!$v.form.email_candidat_mk_co.required"
                    >Veuillez insérer votre adresse mail</span
                  >
                  <span v-if="!$v.form.email_candidat_mk_co.email"
                    >Veuillez insérer une adresse mail valide</span
                  >
                </div>
              </div>
              <div
                v-for="(input, index) in files"
                :key="`${index}`"
                class="col-12"
                :class="index != 0 && 'gy-2'"
              >
                <div class="row">
                  <div class="col-5">
                    <label for="inputNomAD" class="form-label"
                      >Documents Requis</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      required
                      placeholder="ex: curriculum vitae"
                      v-model="files[index].name"
                    />
                  </div>
                  <div class="col-5">
                    <label for="inputAD" class="form-label"
                      >Téléverser le fichier</label
                    >
                    <input
                      type="file"
                      class="form-control"
                      required
                      accept="application/pdf"
                      @change="(e) => changeFile(e, index)"
                    />
                    <div id="inputAD" class="form-text">En format .pdf</div>
                  </div>
                  <div
                    class="col-2 d-flex align-items-center justify-content-between"
                  >
                    <div
                      @click="addField()"
                      class="btn btn-primary shadow rounded-circle p-2"
                    >
                      <IcoAdd />
                    </div>
                    <div
                      @click="removeField(index)"
                      v-show="files.length > 1"
                      class="btn btn-primary shadow rounded-circle p-2"
                    >
                      <IcoDel />
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-12">
                <label for="inputMessage" class="form-label">Details</label>
                <textarea
                  class="form-control"
                  id="inputMessage"
                  rows="4"
                  v-model="form.message_candidat_mk_co"
                  style="resize: none"
                ></textarea>
              </div>
              <div class="mt-3">
                <input
                  type="checkbox"
                  class="form-check-input me-2"
                  required
                  v-model="consent"
                />
                <span
                  >J'ai bien lu et approuvé les Mentions Légales du site</span
                >
              </div>
              <div class="mt-2">
                <input
                  type="checkbox"
                  class="form-check-input me-2"
                  required
                  v-model="cgv"
                />
                <span
                  >J'accepte sans réserve les Conditions Générales de
                  MK-co</span
                >
              </div>
            </section>
          </div>
          <div class="modal-footer mx-2">
            <button
              type="button"
              class="btn btn-secondary me-0"
              data-bs-dismiss="modal"
              ref="close"
              @click="closeModalRejoindre"
            >
              Annuler
            </button>
            <button
              v-if="loading"
              class="btn btn-primary"
              type="button"
              disabled
            >
              <span
                class="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Envoi...
            </button>
            <button
              v-else
              type="submit"
              class="btn btn-primary ms-3"
              formnovalidate="formnovalidate"
            >
              Je postule
            </button>
          </div>
        </form>
      </div>
    </div>
    <!-- Modal -->
  </div>
</template>

<script>
import IcoAdd from "vue-material-design-icons/Plus.vue";
import IcoDel from "vue-material-design-icons/Close.vue";
import { addCandidatMk } from "../../api/candidatMk";
import { success, error } from "../../utils/toast";
import { required, email } from "vuelidate/lib/validators";
import { socket } from "../../api/socket";

export default {
  name: "ModalRejoindre",
  components: {
    IcoAdd,
    IcoDel,
  },
  data() {
    return {
      form: {
        full_name_candidat_mk_co: "",
        tel_candidat_mk_co: "",
        email_candidat_mk_co: "",
        message_candidat_mk_co: "",
      },
      files: [{ name: "", file: null }],
      loading: false,
      submitted: false,
      consent: false,
      cgv: false,
    };
  },
  validations: {
    form: {
      full_name_candidat_mk_co: { required },
      tel_candidat_mk_co: { required },
      email_candidat_mk_co: { required, email },
    },
  },
  methods: {
    addField() {
      this.files.push({ name: "", file: null });
    },
    removeField(index) {
      this.files.splice(index, 1);
    },
    changeFile(e, index) {
      const reader = new FileReader();
      try {
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = (event) => {
          const base64 = event.target.result;
          if (base64) {
            this.files[index].file = base64;
          }
        };
      } catch {
        this.files[index].file = null;
      }
      //this.files[index].file = e.target.files[0];
    },
    closeModalRejoindre() {
      this.submitted = false;
    },
    confirm() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      const isEmptyFile = this.files.some(
        (f) => f.file === null || f.file === ""
      );
      if (isEmptyFile) {
        error("Téléverser le fichier restant");
      } else {
        if (this.consent && this.cgv) {
          this.loading = true;
          addCandidatMk(this.form, this.files).then((result) => {
            if (result.data.error) {
              this.loading = false;
              error(result.data.error, "");
            } else {
              socket.emit("send-notif", {
                data: result.data,
                type: "MK",
                name: this.form.full_name_candidat_mk_co,
              });
              this.loading = false;
              this.$refs.close.click();
              success("Candidature envoyé");
            }
          });
        } else {
          error("Veuillez accepter les consentements");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
